// TODO: https://jira.plaid.com/browse/SITE-2182
// Clean this up, audit for actual variants we need to support
import React from 'react';
import cx from 'classnames';
import Checkmark from 'src/components/SVGs/Icons/Checkmark';

// components
import { Unless } from 'src/components/helpers';
import { BetaLabel } from './Surfaces/Label';
import Image from 'src/components/Image';
import { Link } from 'src/components/Inputs';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';

export const hasBetaFeatures = (collection = []) => {
  return collection.some((item) => {
    return item?.isBetaFeature;
  });
};

const Features = (props) => {
  return (
    <div
      className={cx('features-container', {
        'features-container--hasBetaFeature': hasBetaFeatures(
          props['feature-content'],
        ),
      })}
      data-testid='features'
    >
      <div className='grid-container'>
        {Boolean(props['header-section']) && (
          <React.Fragment>
            <div className='grid-x align-justify'>
              <div className='cell small-12 medium-5'>
                <h6 className='features-label hide-for-aeo'>
                  {props['header-subtext']}
                </h6>
              </div>
              <div className='cell medium-7'></div>
              {Boolean(props['header-text']) && (
                <React.Fragment>
                  <div className='cell small-12 medium-5'>
                    <h4 className='features-title'>{props['header-text']}</h4>
                  </div>
                  <div className='cell medium-7'></div>
                </React.Fragment>
              )}
            </div>
          </React.Fragment>
        )}

        <div className='grid-x align-justify grid-margin-x'>
          {props['feature-count'] === 'one' ? (
            <>
              <div className='cell small-12 medium-12 features-row'>
                <div className='grid-x align-justify grid-margin-x'>
                  <div className='cell medium-6'>
                    <Image
                      className='features-img--single-feature'
                      src={props['feature-img']}
                      width={props.featureImgWidth}
                      height={props.featureImgHeight}
                      alt=''
                    />
                  </div>
                  <div className='cell medium-6'>
                    <h4>{props['feature-title']}</h4>
                    <p>{props['feature-body']}</p>
                    {Boolean(props.checklist) && (
                      <React.Fragment>
                        <ul className='checklist mb2'>
                          {props['checklist-items'].map((item, i) => {
                            return (
                              <React.Fragment key={i}>
                                <li className='checklist-item'>
                                  <span className='check-icon'>
                                    <Checkmark />
                                  </span>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: item['checklist-item-text'],
                                    }}
                                  />
                                </li>
                              </React.Fragment>
                            );
                          })}
                        </ul>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {props['feature-count'] === 'two' ? (
            <>
              {props['feature-content'].map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <div className='cell small-12 medium-5 features-column-item'>
                      <div className='grid-x align-justify grid-margin-x'>
                        <div className='cell medium-12'>
                          <Image
                            className='features-img--small-img'
                            src={item['feature-img']}
                          />
                          <h5 className='features-column-item-text'>
                            {item['feature-title']}
                          </h5>
                          <p
                            className='feature-body'
                            dangerouslySetInnerHTML={{
                              __html: item['feature-body'],
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='cell medium-1'></div>
                  </React.Fragment>
                );
              })}
            </>
          ) : null}

          {props['feature-count'] === 'three' &&
            (props['items-in-columns']
              ? props['feature-content'].map((item, i) => {
                  return (
                    <React.Fragment key={`feature-${i}`}>
                      <div
                        className={`cell small-12 ${
                          item['wider-feature'] ? 'medium-4' : 'medium-3'
                        } features-column-item ${
                          item['prevent-widow'] ? 'prevent-widow' : ''
                        }`}
                      >
                        {item['feature-url'] ? (
                          <div className='grid-x align-justify grid-margin-x'>
                            <div className='cell medium-12'>
                              <Image
                                className={cx(
                                  'features-img',
                                  `${item['max-width']}`,
                                  item.imgClasses,
                                )}
                                src={item['feature-img']}
                              />
                              {item.isBetaFeature && (
                                <div className='feature-betaFeatureLabel'>
                                  <BetaLabel />
                                </div>
                              )}
                              <h5 className='features-column-item-text'>
                                {item['feature-title']}
                              </h5>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item['feature-body'],
                                }}
                              />
                              <Link href={item['feature-url']}>
                                <a>
                                  <span className='feature-section-link-text'>
                                    {item['feature-link-text'] || 'Learn more'}{' '}
                                    <ArrowRightSingleSmall />
                                  </span>
                                </a>
                              </Link>
                            </div>
                          </div>
                        ) : (
                          <div className='grid-x align-justify grid-margin-x'>
                            <div className='cell medium-12'>
                              <Image
                                className={cx(
                                  'features-img--small-img',
                                  item.imgClasses,
                                )}
                                src={item['feature-img']}
                              />
                              {item.isBetaFeature && (
                                <div className='feature-betaFeatureLabel'>
                                  <BetaLabel />
                                </div>
                              )}
                              <h5 className='features-column-item-text'>
                                {item['feature-title']}
                              </h5>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item['feature-body'],
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <Unless condition={item['wider-feature']}>
                        <div className='cell medium-1'></div>
                      </Unless>
                    </React.Fragment>
                  );
                })
              : props['feature-content'].map((item, index) => {
                  return (
                    <div
                      className={`cell small-12 medium-6 ${
                        index === 0 ? 'features-row' : ''
                      } ${index === 1 ? 'features-row' : ''}`}
                      key={`three-col-feature-${index}`}
                    >
                      <div className='grid-x align-justify grid-margin-x'>
                        <div className='cell medium-4'>
                          <Image
                            className='features-img'
                            src={item['feature-img']}
                          />
                        </div>
                        <div className='cell medium-8'>
                          <h5>{item['feature-title']}</h5>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item['feature-body'],
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }))}

          {props['feature-count'] === 'four' ? (
            <>
              {props['feature-content'].map((item, i) => {
                return (
                  <React.Fragment key={`feature-${i}`}>
                    {' '}
                    <div
                      className={`cell small-12 medium-6 ${
                        i === 0 ? 'features-row' : ''
                      } ${i === 1 ? 'features-row' : ''}`}
                    >
                      <div className='grid-x align-justify grid-margin-x'>
                        <div className='cell medium-4'>
                          <Image
                            className='features-img'
                            src={item['feature-img']}
                          />
                        </div>
                        <div className='cell medium-8'>
                          <h5>{item['feature-title']}</h5>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item['feature-body'],
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}{' '}
            </>
          ) : null}

          {props['feature-count'] === 'five' && (
            <>
              {props['items-in-columns']
                ? props['feature-content'].map((item, i) => {
                    return (
                      <React.Fragment key={`feature-${i}`}>
                        <div
                          className={`cell small-12 ${
                            props['wider-feature'] ? 'medium-4' : 'medium-3'
                          } features-column-item ${
                            props['prevent-widow'] ? 'prevent-widow' : ''
                          }`}
                        >
                          {item['feature-url'] ? (
                            <Link href={item['feature-url']}>
                              <a>
                                <div className='grid-x align-justify grid-margin-x'>
                                  <div className='cell medium-12'>
                                    <Image
                                      className={`features-img ${item['max-width']}`}
                                      src={item['feature-img']}
                                    />
                                    <h5 className='features-column-item-text'>
                                      {item['feature-title']}
                                    </h5>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: item['feature-body'],
                                      }}
                                    />
                                    <span className='feature-section-link-text'>
                                      {item['feature-link-text'] ||
                                        'Learn more'}{' '}
                                      <ArrowRightSingleSmall />
                                    </span>
                                  </div>
                                </div>
                              </a>
                            </Link>
                          ) : (
                            <div className='grid-x align-justify grid-margin-x'>
                              <div className='cell medium-12'>
                                <Image
                                  className='features-img--small-img'
                                  src={item['feature-img']}
                                />
                                <h5 className='features-column-item-text'>
                                  {item['feature-title']}
                                </h5>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item['feature-body'],
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <Unless condition={props['wider-feature']}>
                          <div className='cell medium-1'></div>
                        </Unless>
                      </React.Fragment>
                    );
                  })
                : props['feature-content'].map((item, index) => {
                    return (
                      <div
                        className={`cell small-12 medium-6 ${
                          index === 0 ? 'features-row' : ''
                        } ${index === 1 ? 'features-row' : ''}`}
                      >
                        <div className='grid-x align-justify grid-margin-x'>
                          <div className='cell medium-4'>
                            <Image
                              className='features-img'
                              src={item['feature-img']}
                            />
                          </div>
                          <div className='cell medium-8'>
                            <h5>{item['feature-title']}</h5>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item['feature-body'],
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              <div className='cell medium-4 hide-for-mobile'></div>
            </>
          )}

          {props['feature-count'] === 'six' ? (
            <>
              {props['feature-content'].map((item, i) => {
                return (
                  <React.Fragment key={`feature-${i}`}>
                    {' '}
                    <div className='cell small-12 medium-6 features-column-item'>
                      <div className='grid-x align-justify grid-margin-x'>
                        <div className='cell medium-2 small-2'>
                          <Image
                            className='features-img--small-img'
                            src={item['feature-img']}
                          />
                        </div>
                        <div className='cell medium-10 small-10'>
                          <h5 className='features-column-item-text'>
                            {item['feature-title']}
                          </h5>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item['feature-body'],
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}{' '}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Features;
