// TODO: Refactor/GCPify - @ https://jira.plaid.com/browse/SITE-1991

import React from 'react';
import cx from 'classnames';

// components
import ShowcaseCard from 'src/components/showcase-card';
import HomepagePhone from 'src/components/homepage-phone';
import { Button, Link } from 'src/components/Inputs';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';
import Image from 'src/components/Image';

//helper
import { optimizeImage } from 'src/lib/image-helpers';

const Showcase = (props) => {
  return (
    <article
      className={cx(
        'showcase',
        `showcase--${props.type}`,
        props.classes,
        'background',
        props.type === 'icon-grid' &&
          (props.nthChild ? `is-${props.nthChild}-child` : 'is-odd-child'),
        {
          [`background--color-${props['bg-color']}`]: props['bg-color'],
          'showcase--reverse': props.reverse,
          'showcase--extra-padding': props['extra-padding'],
          'showcase--large-image': props['large-image'],
          'showcase--margin-bottom': props['margin-bottom'],
          'showcase--max-width-image': props['maxWidthImage'],
        },
      )}
    >
      <div className='grid-container showcase__container'>
        <div className='grid-x'>
          <div
            className={`cell ${
              ['single-image', 'video', 'icon-grid'].includes(props.type)
                ? 'large-5'
                : 'large-4'
            }`}
          >
            <section className='showcase__setup'>
              {props.label && (
                <h6 className='showcase__label hide-for-aeo'>{props.label}</h6>
              )}
              <h4 className='showcase__title'>{props.title}</h4>
              <div className='showcase__description'>
                {Array.isArray(props.description) ? (
                  props.description.map((desc) => {
                    return <p dangerouslySetInnerHTML={{ __html: desc }}></p>;
                  })
                ) : (
                  <p dangerouslySetInnerHTML={{ __html: props.description }} />
                )}

                {props.bullets && (
                  <ul className='showcase__bullets'>
                    {props.bullets.map((item, i) => {
                      return (
                        <li
                          className='showcase__bullets__li'
                          dangerouslySetInnerHTML={{ __html: item.text }}
                          key={`bullet-${i}`}
                        ></li>
                      );
                    })}
                  </ul>
                )}
                {props.secondaryDescription &&
                  (Array.isArray(props.secondaryDescription) ? (
                    props.secondaryDescription.map((desc) => {
                      return <p dangerouslySetInnerHTML={{ __html: desc }}></p>;
                    })
                  ) : (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: props.secondaryDescription,
                      }}
                    ></p>
                  ))}
              </div>
              {props['has-cta'] && props['cta-url'] && (
                <div
                  className={`showcase__cta ${
                    props['cta-class']
                      ? 'showcase__cta--' + props['cta-class']
                      : ''
                  }`}
                >
                  <Button
                    href={props['cta-url']}
                    className={cx(props['button-class'])}
                    secondary={/\s?hollow\s?/.test(props['button-class'])}
                    fullWidth
                  >
                    {props['cta-text']}
                  </Button>
                </div>
              )}
              {props['has-text-cta'] && props['text-cta-url'] && (
                <div className='showcase__text-cta'>
                  <Link href={props['text-cta-url']}>
                    <a>
                      <span className='feature-section-link-text'>
                        {props['text-cta-text']} <ArrowRightSingleSmall />
                      </span>
                    </a>
                  </Link>
                </div>
              )}
            </section>
            {/* /.showcase__setup */}
          </div>
          <div
            className={`cell ${
              ['single-image', 'video', 'icon-grid'].includes(props.type)
                ? 'large-7'
                : 'large-8'
            }`}
          >
            <section className='showcase__exhibit'>
              {props['img-brushtroke'] && (
                <div
                  className={`showcase__accent ${
                    props['brushstroke-shrink-to-fit']
                      ? 'showcase__accent--fit-to-container'
                      : ''
                  }`}
                  style={{
                    backgroundImage: `url('${optimizeImage(
                      props['img-brushtroke'],
                    )}')`,
                  }}
                ></div>
              )}
              <div className='showcase__objects'>
                {props.type === 'cards'
                  ? props.cards.map((item, i) => {
                      return (
                        <React.Fragment key={i}>
                          <ShowcaseCard {...item} index={i} />
                        </React.Fragment>
                      );
                    })
                  : null}

                {props.images && props.type === 'dual-image'
                  ? props.images.map((item, i) => {
                      return (
                        <React.Fragment key={i}>
                          {' '}
                          <div
                            className={`showcase-image ${
                              item['has-shadow']
                                ? 'showcase-image--has-boxshadow'
                                : ''
                            }`}
                          >
                            <Image
                              src={item['img-src']}
                              alt={item['alt-text']}
                            />
                          </div>
                        </React.Fragment>
                      );
                    })
                  : null}
                {props.images && props.type === 'single-image'
                  ? props.images.map((item, i) => {
                      return (
                        <React.Fragment key={i}>
                          {' '}
                          <div className='showcase-image'>
                            <Image
                              src={item['img-src']}
                              alt={item['alt-text']}
                            />
                          </div>
                        </React.Fragment>
                      );
                    })
                  : null}
                {props.videos && props.type === 'video'
                  ? props.videos.map((item, i) => {
                      return (
                        <React.Fragment key={i}>
                          <div className='showcase-video'>
                            {item['video-frame-image'] && (
                              <div
                                className='showcase-video__frame'
                                style={{
                                  backgroundImage: `url('${optimizeImage(
                                    item['video-frame-image'],
                                  )}')`,
                                }}
                              ></div>
                            )}
                            <video
                              className='show-for-medium'
                              autoPlay
                              loop
                              muted
                              width={item['video-width']}
                              height={item['video-height']}
                              poster={optimizeImage(
                                item['video-poster-image'] ||
                                  item['video-fallback-image'],
                              )}
                            >
                              {item['video-formats'].map((video, i) => {
                                return (
                                  <React.Fragment key={i}>
                                    <source
                                      src={video.url}
                                      type={`video/${video.type}`}
                                    />
                                  </React.Fragment>
                                );
                              })}
                            </video>
                            <Image
                              className='hide-for-medium'
                              src={item['video-fallback-image']}
                            />
                          </div>
                        </React.Fragment>
                      );
                    })
                  : null}
                {props.type === 'icon-grid' &&
                  props['icon-grid'] &&
                  props['icon-grid'].length && (
                    <ul className='icon-grid'>
                      {props['icon-grid'].map((item, i) => {
                        return (
                          <li
                            className='icon-grid__item'
                            key={`icon-grid-item-${i}`}
                          >
                            <Link href={item.url}>
                              <a
                                className='icon-grid-link'
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                {''}
                              </a>
                            </Link>
                            <Image
                              src={item['img-src']}
                              alt={item['alt-text']}
                            />
                            <div className='icon-grid-item-text'>
                              <h5>{item.name}</h5>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              />
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  )}
              </div>
              {props['img-illustration'] && (
                <>
                  {' '}
                  <div className='showcase__art'>
                    <Image src={props['img-illustration']} alt='' />
                  </div>
                </>
              )}
              {props['phone-video'] && (
                <div className='showcase__video-phone'>
                  <HomepagePhone />
                </div>
              )}
            </section>
            {/* /.showcase__exhibit */}
          </div>
        </div>
      </div>
    </article>
  );
};

export default Showcase;
